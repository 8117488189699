@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.aboutMe {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}


.container1 {
  height: 100%;
  width: 100%;
}

.container1-text {
  width: 60%;
  background-color: #1DAFEC;
  padding: 30px;
  top: 0;
}

.container1-text > h1 {
  font-size: 30px;
  color: #fff;
}

.container1-text > p {
  font-size: 22px;
  color: #fff;
}

.container1-desc {
  width: 60%;
  background-color: #fff;
  padding: 30px;
  top: 0;
}

.container-contact {
  width: 100%;
  background-color: #1DAFEC;
  padding: 30px;
  top: 0;
  align-items: center;
}

.contact-text {
  font-size: 20px;
  color:#fff;
  padding: 1px;
  text-align: center;
}

.contact-text > h1 {
  text-decoration: underline;
}

.contact-text > p {
  text-decoration: none;
}



.container1-desc > h1 {
  font-size: 24px;
  color:#1DAFEC;
  padding-bottom: 10px;
  text-decoration: underline;
}

.container1-desc > p {
  font-size: 18px;
  color:#1DAFEC;
}

.container1-image {
  width: 40%;
  height: 500px;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 8%;
}

.ImageAbout {
  height: 500px;
  width: 100%;
  background-position: center;
  object-fit: contain;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ImageAbout2 {
  height: 400px;
  width: 100%;
  background-position: center;
  object-fit: contain;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.map {
  display: flex;
  padding: 20px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}






@media screen and (max-width: 600px) {

  .container1-text {
    width: 100%;
    background-color: #1DAFEC;
    padding: 10px;
    top: 0;
    text-align: center;
  }

  .container1-desc {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    top: 0;
    text-align: center;
  }

  .container1-image {
    width: 90%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    padding: 10px;
  }

  .ImageAbout {
    height: 100%;
    width: 100%;
    background-position: center;
    object-fit: contain;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .map {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  max-width: 90vw;
}


}



