.form-container {
    height: 500px;
    width: 500px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 10px;
    border-radius: 2px;
    border-style: solid;
    border-color: white;
    font-size: 20px;
    display: inline-flexbox;
}