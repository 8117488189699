.footer-container {
    background-color: #1DAFEC;
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    height: auto;
}

.footer-links {
    width: 100%;
    max-width: 100%; /*EDIT THIS TO 50% on phone */
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: row;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 16px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #fff;
    transition: 0.3s ease-out;
  }

  .fa {
    padding: 10px;
    font-size: 20px;
    width: 50px;
    text-align: center;
    text-decoration: none;
  }

  .fa:hover {
    opacity: 0.7;
  }
  /* Facebook */
  .fa-facebook {
    color: white;
  }
  
  /* Twitter */
  .fa-youtube {
    color: white;
  }
  
  .fa-instagram {
    color: white;
  }

  .bottom-name {
    text-align: center;
    font-size: 8px;
    padding: 10px;
    color: #fff;
  }

  @media screen and (max-width: 600px) {

    .footer-container {
      background-color: #1DAFEC;
      padding: 0.5rem 0 0.5rem 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 10px;
  }

    
  .footer-link-wrapper {
    align-items: center;
    
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }

  }