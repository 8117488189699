.container {
    background-color: #1DAFEC;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.steps-container {
    height: 100%;
    width: 100%;
    background-color: #1DAFEC;
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    justify-content: center;
    align-items: center;
    color: rgb(32, 32, 32);
    padding: 10px;
}

.steps-container2 {
    height: 100%;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    justify-content: center;
    align-items: center;
    color: #1DAFEC;
    padding: 10px;
}

.header {
    padding: 20px;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: underline;
    width: 50%;
    display: flex;
    color: #fff;
}

.header h1 {
    justify-content: center;
    align-items: center;
    color: #fff;
}

.step12 {
    height: 220px;
    width: 350px;
    padding: 10px;
    background-color: #1DAFEC;
    color: #fff;
}

.testimonial-container {
    height: 260px;
    width: 350px;
    padding: 10px;
}

.step12 h2 {
    text-decoration: underline;
    color: #fff;
}

.ImageSteps {
    height: 100%;
    width: 100%;
    background-position: center;
    object-fit: contain;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

.videotestimonial {
    height: 220px;
    width: 330px;
    z-index: 99;
    position: absolute;
}

@media screen and (max-width: 960px) {
    .steps-container {
        height: 100%;
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        flex-wrap:nowrap;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }
    .steps-container2 {
        height: 100%;
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        flex-wrap:nowrap;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }

    .step12 {
        height: 50%;
        width: 100%;
        padding: 10px;
    }
}