.navbar {
    background: white;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }

  .nav-logo {
    height: 60px;
    width: 60px;
    background-image: url("../images/logo.svg");
    background-position: center;
    object-fit: contain;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
  }
  
  .fas fa-greater-than, .fas fa-less-than {
    font-size: 10px;
  }

a {
  color:#1DAFEC;
  padding-left: 10px;
  }

a:visited {
  color:#1DAFEC;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 75vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #1DAFEC;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #1DAFEC;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #1DAFEC;
  }
  
  .nav-links-mobile {
    display: none;
  }

  .lang-btn {
    height: 40px;
    position: absolute;
    left: 95%;
    margin-top: 20px;
    border-radius: 10px;
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      justify-content: start;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #fff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #1DAFEC;
      color: #FFF;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #1DAFEC;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #FFF;
      padding: 14px 20px;
      border: 1px solid #1DAFEC;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #111;
      color: #FFF;
      transition: 250ms;
    }

    .lang-btn {
      height: 40px;
      position:relative;
      left: 0%;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 20%;
      text-decoration: none;
      font-size: 1.5rem;
    }
  }